
import type { EndpointOption } from './types.js';

import { DUNITER_GDEV_GENESIS } from '../api/constants.js';
// import { getTeleports } from './util.js';
import { chainsDuniterSVG } from '@polkadot/apps-config/ui/logos/chains';


export const prodGDev: EndpointOption = {
    dnslink: 'gdev',
    genesisHash: DUNITER_GDEV_GENESIS,
    info: 'gdev',
    isPeopleForIdentity: true,
    isRelay: false,
    providers: {
      Poka: 'wss://gdev.p2p.legal/ws',
      HugoTrentesaux: 'wss://gdev.coinduf.eu/ws',
      Pini: 'wss://gdev.pini.fr/ws',
      vit: 'wss://vit.fdn.org/ws',
      cgeek: 'wss://gdev.cgeek.fr/ws',
      '1000i100': 'wss://ws.gdev.1000i100.fr',
      Syoul: 'wss://g1.syoul.fr/ws',
    },
    text: 'ĞDev',
    ui: {
      color: '#ffd17d',
      identityIcon: 'polkadot',
      logo: chainsDuniterSVG
    }
  };
  